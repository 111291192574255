import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // 设置主色调（蓝色）
    },
    secondary: {
      main: '#f50057', // 设置次色调（粉色）
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif', // 设置字体
  },
});

export default theme;
