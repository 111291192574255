import React from 'react';
import { Container, Typography, Card, CardContent, Grid } from '@mui/material';
import '../styles/About.css';

const About = () => {
  return (
    <Container maxWidth="md" className="about-container" style={{ marginTop: '20px' }}>
      {/* 页面标题 */}
      <Typography variant="h3" component="h1" gutterBottom color="primary" align="center">
        关于思洁舞蹈工作室
      </Typography>

      {/* 工作室简介 */}
      <Card style={{ marginTop: '20px', padding: '20px', backgroundColor: '#f9f9f9' }}>
        <CardContent>
          <Typography variant="body1" color="text.secondary">
            思洁舞蹈工作室专注于东方舞和肚皮舞的教学，融合传统与现代元素，旨在帮助学员提升舞蹈技能、艺术表现力与身体健康。
          </Typography>
        </CardContent>
      </Card>

      {/* 主营课程 */}
      <Typography variant="h4" component="h2" gutterBottom style={{ marginTop: '30px' }}>
        主营课程
      </Typography>
      <Grid container spacing={4}>
        {/* 课程1 */}
        <Grid item xs={12} sm={6} md={4}>
          <Card style={{ backgroundColor: '#f9f9f9' }}>
            <CardContent>
              <Typography variant="h6" component="h3">
                东方舞基础课程
              </Typography>
              <Typography variant="body2" color="text.secondary">
                针对初学者设计，教授基础步伐、动作控制与舞蹈仪态。
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* 课程2 */}
        <Grid item xs={12} sm={6} md={4}>
          <Card style={{ backgroundColor: '#f9f9f9' }}>
            <CardContent>
              <Typography variant="h6" component="h3">
                中高级技巧提升
              </Typography>
              <Typography variant="body2" color="text.secondary">
                专注于腹部波动、胯部圆弧与手臂流线等技巧的提升。
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* 课程3 */}
        <Grid item xs={12} sm={6} md={4}>
          <Card style={{ backgroundColor: '#f9f9f9' }}>
            <CardContent>
              <Typography variant="h6" component="h3">
                艺术表演编排
              </Typography>
              <Typography variant="body2" color="text.secondary">
                提供个人或团体表演的编舞训练，提升舞台表现力。
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* 课程4 */}
        <Grid item xs={12} sm={6} md={4}>
          <Card style={{ backgroundColor: '#f9f9f9' }}>
            <CardContent>
              <Typography variant="h6" component="h3">
                特色风格探索
              </Typography>
              <Typography variant="body2" color="text.secondary">
                包括中东风情、现代东方舞等特色风格课程。
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* 课程5 */}
        <Grid item xs={12} sm={6} md={4}>
          <Card style={{ backgroundColor: '#f9f9f9' }}>
            <CardContent>
              <Typography variant="h6" component="h3">
                个性化定制班
              </Typography>
              <Typography variant="body2" color="text.secondary">
                针对特定需求或表演目标提供一对一私教课程。
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;

