import React from 'react';
import '../styles/Students.css';

const Students = () => {
  return (
    <div className="students-container">
      <h1>学员风采</h1>
      <p>这里是学员风采的信息。</p>
    </div>
  );
};

export default Students;

