import React from 'react';
import '../styles/Contact.css';

const Contact = () => {
  return (
    <div className="contact-container">
      <h1>联系我们</h1>
      <p>这里是联系我们的信息。</p>
    </div>
  );
};

export default Contact;

