 
import React from 'react';
import '../styles/News.css';

const News = () => {
  return (
    <div className="news-container">
      <h1>新闻动态</h1>
      <p>这里是新闻动态的信息。</p>
    </div>
  );
};

export default News;
