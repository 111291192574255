import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AppBar, Tabs, Tab, Container } from '@mui/material';
import Home from './components/Home';
import About from './components/About';
import Courses from './components/Courses';
import Teachers from './components/Teachers';
import Students from './components/Students';
import News from './components/News';
import Contact from './components/Contact';
import './App.css'; // 引入自定义的 CSS 文件

const App = () => {
  // 设置导航选项状态
  const [tabIndex, setTabIndex] = useState(0);

  // 导航切换处理
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <BrowserRouter>
      {/* 使用 Material UI 的 AppBar 和 Tabs */}
      <AppBar position="static" color="primary">
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="secondary"
          textColor="inherit"
        >
          <Tab label="首页" href="/" />
          <Tab label="关于我们" href="/about" />
          <Tab label="课程介绍" href="/courses" />
          <Tab label="师资力量" href="/teachers" />
          <Tab label="学员风采" href="/students" />
          <Tab label="新闻动态" href="/news" />
          <Tab label="联系我们" href="/contact" />
        </Tabs>
      </AppBar>

      {/* 使用 Container 管理页面布局 */}
      <Container maxWidth="lg" style={{ marginTop: '20px' }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/teachers" element={<Teachers />} />
          <Route path="/students" element={<Students />} />
          <Route path="/news" element={<News />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Container>
    </BrowserRouter>
  );
};

export default App;


