import React from 'react';
import '../styles/Courses.css';

const Courses = () => {
  return (
    <div className="courses-container">
      <h1>课程介绍</h1>
      <p>这里是课程介绍的信息。</p>
    </div>
  );
};

export default Courses;

