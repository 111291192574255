import React from 'react';
import { Container, Typography, Card, CardContent, Button, CardMedia } from '@mui/material'; // 引入 Material UI 组件
import '../styles/Home.css'; // 保留自定义样式的支持（如果需要）

const Home = () => {
  return (
    <Container maxWidth="lg" className="home-container" style={{ marginTop: '20px' }}>
      {/* 页面标题 */}
      <Typography variant="h3" component="h1" gutterBottom color="primary" align="center">
        欢迎来到思洁艺术舞蹈工作室！
      </Typography>

      {/* 描述卡片 */}
      <Card style={{ marginTop: '20px', padding: '20px', backgroundColor: '#f9f9f9' }}>
        <CardMedia
          component="img"
          alt="舞蹈课图片"
          height="300"
          image="/images/001.jpg" // 更换为实际的图片路径
          title="舞蹈课图片"
        />
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            关于我们
          </Typography>
          <Typography variant="body1" color="text.secondary">
            思洁艺术舞蹈工作室专注于提供高质量的东方舞课程，无论您是初学者还是希望提升专业技能，我们的课程都能满足您的需求。
          </Typography>
        </CardContent>
      </Card>

      {/* 特色课程介绍 */}
      <Typography variant="h5" component="h2" style={{ marginTop: '20px', align: 'center' }}>
        特色课程
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
        {/* 课程1 */}
        <Card style={{ width: '30%', margin: '20px', padding: '20px', backgroundColor: '#f9f9f9' }}>
          <CardMedia
            component="img"
            alt="课程1图片"
            height="200"
            image="/images/002.jpg" // 更换为实际的图片路径
            title="课程1图片"
          />
          <CardContent>
            <Typography variant="h6" component="h3">
              课程一：东方舞基础
            </Typography>
            <Typography variant="body1" color="text.secondary">
              为初学者设计的东方舞基础课程，掌握基础舞步和舞蹈感觉。
            </Typography>
          </CardContent>
        </Card>

        {/* 课程2 */}
        <Card style={{ width: '30%', margin: '20px', padding: '20px', backgroundColor: '#f9f9f9' }}>
          <CardMedia
            component="img"
            alt="课程2图片"
            height="200"
            image="/images/003.jpg" // 更换为实际的图片路径
            title="课程2图片"
          />
          <CardContent>
            <Typography variant="h6" component="h3">
              课程二：进阶肚皮舞
            </Typography>
            <Typography variant="body1" color="text.secondary">
              针对有一定基础的学生，深入学习肚皮舞的技巧和表演。
            </Typography>
          </CardContent>
        </Card>

        {/* 可以继续添加更多课程卡片 */}
      </div>

      {/* 行动按钮 */}
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <Button variant="contained" color="secondary" size="large" href="/about">
          查看更多详情
        </Button>
      </div>
    </Container>
  );
};

export default Home;




