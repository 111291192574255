import React from 'react';
import '../styles/Teachers.css';

const Teachers = () => {
  return (
    <div className="teachers-container">
      <h1>师资力量</h1>
      <p>这里是师资力量的信息。</p>
    </div>
  );
};

export default Teachers;

