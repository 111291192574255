import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider, CssBaseline } from '@mui/material'; // 引入 Material UI 的主题组件
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import theme from './theme'; // 引入自定义主题文件

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* 包裹整个应用的主题提供者 */}
    <ThemeProvider theme={theme}>
      {/* 添加 CssBaseline 以应用 Material UI 的全局样式 */}
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// 性能监控工具（可选）
reportWebVitals();

